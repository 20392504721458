<template>
  <div class="step-fallback">
    <h4 class="step-fallback__title">Vielen Dank!</h4>

    <p class="step-fallback__text">
      Wir werden deine Konfiguration an die angegebene E-Mail schicken. Du solltest sie in ein paar Minuten erhalten.
    </p>

    <div class="step-fallback__sep"></div>

    <div class="step-fallback__box">
      <h5 class="step-fallback__box-title">Noch Fragen?</h5>

      <div class="step-fallback__box-user">
        <img src="@/assets/img/team.jpg" alt="" />

        <p>Das Merx Team</p>
      </div>

      <div class="step-fallback__box-info">
        <i class="fas fa-phone-alt"></i>
        <h5>0800-313-313</h5>

        <p>Mo–Fr:&nbsp;&nbsp;8 bis 18 Uhr</p>

        <p>Sa:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9 bis 16 Uhr</p>
      </div>

      <div class="step-fallback__box-mail">
        <i class="fas fa-at"></i>

        <p>welcome@merx.ch</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fallback",
  data() {
    return {
      list: [
        "Passgenau auf deinen Grundriss abgestimmt",
        "Mit Elektrogeräten von Top Herstellern",
        "Professionelle Fachberatung von Küchenexperten",
      ],
    };
  },
  mounted() {
    this.$gtm.trackView('Preisrechner Bestätigung Konfiguration', 'https://preisrechner.merx.ch/confirmation-send-config');
  },
  methods: {
    selectItem(value) {
      this.selected = value;
      this.$emit("changeValue", value);
    },
    nextStep() {
      if (this.selected) {
        this.$emit("nextStep");
      }
    },
  },
};
</script>

<style lang="scss">
.step-fallback {
  padding: 35px 10px 20px;

  &__title {
    margin-bottom: 30px;

    font-family: Helvetica;
    font-size: 31px;
    line-height: 37px;
    font-weight: 700;
    text-align: center;
    color: var(--primary);
  }

  &__text {
    max-width: calc(100% - 50px);
    margin: 0 auto 40px;

    font-family: Helvetica;
    font-size: 16px;
    line-height: 18px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__sep {
    width: 200px;
    height: 2px;
    margin: 0 auto;

    background-color: #000;
    text-align: center;
  }

  &__box {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px 50px;
    justify-content: center;

    max-width: 500px;
    padding: 25px 15px;
    margin: 30px auto 0;

    border: 1px solid #ccc;
    border-radius: 5px;

    @media (max-width: 768px) {
      grid-template-columns: auto;

      padding: 10px 20px;
    }

    &-title {
      grid-column: 1 / -1;

      font-family: Helvetica;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      text-align: center;
    }

    &-user {
      grid-row: span 2;

      text-align: center;

      font-size: 14px;
      line-height: 16px;

      & img {
        margin-bottom: 5px;
      }
    }

    &-info {
      display: inline-grid;
      grid-template-columns: auto auto;
      gap: 2px 5px;
      justify-content: left;

      font-family: Helvetica;
      text-align: left;

      @media (max-width: 768px) {
        gap: 2px 25px;
      }

      & i {
        grid-row: 1 / 4;

        font-size: 20px;
        margin-right: 10px;
      }

      & h5 {
        margin-bottom: 5px;

        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
      }

      & p {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &-mail {
      display: flex;
      justify-content: flex-start;
      align-items: left;

      & i {
        margin-right: 14px;

        font-size: 20px;
      }
    }
  }
}
</style>