<template>
  <div class="steps">
    <div class="steps__bg" @click="hideSteps" />

    <div class="steps__wrap">
      <div class="steps__progress">
        <div
          class="steps__progress-bar"
          :style="{ width: `${currentValue > 10 ? 10 : currentValue}0%` }"
        />
        <span class="steps__progress-text"
          >{{ currentValue > 10 ? 10 : currentValue }}0% geschafft</span
        >
      </div>

      <keep-alive>
        <component
          :is="componentShown"
          :selectedValue="selectedValue"
          @nextStep="nextStep"
          @prevStep="prevStep"
          @submitForm="submitForm"
        ></component>
      </keep-alive>

      <button class="steps__close" @click.stop="hideSteps">schliessen</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Step1 from "@/components/Steps/Step1.vue";
import Step2 from "@/components/Steps/Step2.vue";
import Step3 from "@/components/Steps/Step3.vue";
import Step4 from "@/components/Steps/Step4.vue";
import Step5 from "@/components/Steps/Step5.vue";
import Step6 from "@/components/Steps/Step6.vue";
import Step7 from "@/components/Steps/Step7.vue";
import Step8 from "@/components/Steps/Step8.vue";
import Step9 from "@/components/Steps/Step9.vue";
import Planning from "@/components/Steps/Planning.vue";
import Form from "@/components/Steps/Form.vue";
import Fallback from "@/components/Steps/Fallback.vue";

export default {
  name: "Steps",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
    Planning,
    Form,
    Fallback,
  },
  data() {
    return {
      currentValue: 1,
      telephone: "asd",
      selectedValue: {},
    };
  },
  computed: {
    componentShown() {
      if (this.currentValue === 10) {
        return "Form";
      }
      if (this.currentValue === 11) {
        return "Fallback";
      }
      if (this.currentValue === 12) {
        return "Planning";
      }
      return `Step${this.currentValue}`;
    },
  },
  methods: {
    hideSteps() {
      this.$root.$data.isShowSteps = false;
    },
    nextStep(value, key) {
      this.selectedValue[key] = value;
      this.currentValue++;
    },
    prevStep() {
      this.currentValue--;
    },
    async submitForm({ formData, step }) {
      const merge_fields = {
        FORM: this.selectedValue.Form,
        KCHENRAUM: this.selectedValue.Küchenraum,
        STIL: this.selectedValue.Stil,
        FARBE: this.selectedValue.Farbe,
        MATERIAL: this.selectedValue.Material,
        BACKOFEN: this.selectedValue.Backofen,
        KHLSCHRAN: this.selectedValue.Kühlschrank,
        KOCHFELD: this.selectedValue.Kochfeld,
        FERTIGSTEL: this.selectedValue.Kühlgerät,
        BERATUNG: 0,
      };

      if (formData.telephone) {
        merge_fields.TELEPHONE = formData.telephone;
        merge_fields.BERATUNG = 1;

        this.telephone = formData.telephone;
      }

      if (formData.name) {
        merge_fields.NAME = formData.name;
        merge_fields.BERATUNG = 1;
      }

      axios.post(
        "https://laughing-spence-cd30ed.netlify.app/",
        {
          email: formData.mail,
          merge_fields
        }
      );

      this.currentValue += step;
    },
  },
};
</script>

<style lang="scss">
.steps {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding: 50px;

  @media (max-width: 768px) {
    padding: 15px 0;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.4);
  }

  &__wrap {
    position: relative;
    width: 100%;
    max-height: 100%;
    max-width: 1200px;

    padding: 20px 20px 45px;

    background-color: #fff;
    border: 1px solid #000;
    overflow: auto;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;

    font-family: Helvetica;
    font-size: 13px;
    line-height: 18px;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    cursor: pointer;

    @media (max-width: 768px) {
      top: 8px;
      right: 10px;
    }
  }

  &__progress {
    height: 24px;
    margin: 50px 80px 35px;

    background-color: #f5f5f5;
    border-radius: 12px;
    border: 1px solid #e6e6e6;

    @media (max-width: 768px) {
      height: 14px;
      margin: 35px 15px;
    }

    &-bar {
      width: 10%;
      height: 100%;

      border-radius: 12px;
      background-color: var(--primary);
      transition: 0.3s width ease;
    }

    &-text {
      display: inline-block;
      margin-top: 5px;

      font-family: Helvetica;
      font-size: 16px;
      line-height: 19px;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
</style>