<template>
  <div class="experts">
    <div class="container">
      <h2 class="experts__title">Die Küchenexperten von MERX</h2>

      <div class="experts__wrap">
        <img src="@/assets/img/team.jpg" alt="" class="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Experts",
};
</script>

<style lang="scss">
.experts {
  padding: 32px 0;

  background-color: #f7f6f6;
  text-align: center;

  &__title {
    margin-bottom: 40px;

    font-size: 28px;
    line-height: 35px;
    color: var(--primary);
  }

  &__wrap {
   
    grid-template-columns: 1fr 350px;
    gap: 30px;
    justify-items: center;
    align-items: center;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);

      margin: 0 -10px;
    }
  }

  &__img {
    grid-row: 1 / 3;

    width: 100%;

    @media (max-width: 1100px) {
      grid-row: auto;
      grid-column: 1 / -1;
    }
  }

  &__year,
  &__kitchen {
    font-size: 28px;
    line-height: 35px;

    @media (max-width: 1100px) {
      font-size: 23px;
      line-height: 28px;
    }

    & span {
      font-weight: 400;
    }
  }
}
</style>