<template>
  <div class="step-form">
    <div class="step-form__result">
      <h4 class="step-form__title step-form__result-title">
        Deine Küchen-Konfiguration
      </h4>

      <div class="step-form__result-wrap">
        <div
          v-for="(item, key, index) in selectedValue"
          :key="item"
          class="step-form__result-item"
        >
          <span>{{ index + 1 }}.</span>
          <p>
            <b>{{ key }}</b> {{ item }}
          </p>
        </div>
      </div>
    </div>

    <form @submit.prevent="submitFormFirst" class="step-form__main">
      <h4 class="step-form__title">Nächster Schritt</h4>

      <p class="step-form__subtitle step-form__main-subtitle">
        Konfiguration mit Experten besprechen
      </p>

      <img
        src="@/assets/img/team.jpg"
        srcset="@/assets/img/team.jpg 768vw"
        alt=""
        class="step-form__main-img"
      />

      <p class="step-form__text step-form__main-text">
        Lasse dich in einem
        <b>kostenlosen &amp; unverbindlichen Gespräch</b> von einem
        Küchenexperten beraten:
      </p>

      <input
        type="text"
        placeholder="Name"
        required
        v-model="formFirst.name"
        class="step-form__input step-form__main-input"
      />
      <input
        type="text"
        placeholder="Telefonnummer "
        required
        v-model="formFirst.telephone"
        class="step-form__input step-form__main-input"
      />
      <input
        type="text"
        placeholder="E-Mail"
        required
        v-model="formFirst.email"
        class="step-form__input step-form__main-input"
      />

      <button
        id="send_info_button"
        class="step-form__button step-form__main-button"
      >
        Kontakt senden
      </button>
    </form>

    <form @submit.prevent="submitFormSecond" class="step-form__subscribe">
      <h5 class="step-form__subscribe-subtitle step-form__subtitle">
        Keinen Beratungstermin vereinbaren
      </h5>

      <p class="step-form__subscribe-text step-form__text">
        Wir senden dir deine Küchenkonfiguration per Mail zu:
      </p>

      <input
        type="email"
        placeholder="Deine E-Mail"
        required
        class="step-form__subscribe-input step-form__input"
        v-model="formSecond.mail"
      />

      <button
        type="submit"
        id="send_config_button"
        class="step-form__subscribe-button step-form__button"
      >
        Konfiguration senden
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Form",
  props: {
    selectedValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      formFirst: {
        name: "",
        telephone: "",
        email: "",
      },

      formSecond: {
        mail: "",
      },
    };
  },
  mounted() {
    this.$gtm.trackView(
      "Preisrechner Formular",
      "https://preisrechner.merx.ch/form"
    );
  },
  methods: {
    selectItem(value) {
      this.selected = value;
      this.$emit("changeValue", value);
    },
    nextStep() {
      if (this.selected) {
        this.$emit("nextStep");
      }
    },
    submitFormFirst() {
      if (
        this.formFirst.name === "" ||
        this.formFirst.telephone === "" ||
        this.formFirst.email === ""
      ) {
        return;
      }
      const formData = {
        name: this.formFirst.name,
        telephone: this.formFirst.telephone,
        mail: this.formFirst.email,
      };
      this.$emit("submitForm", { formData: formData, step: 2 });
    },
    submitFormSecond() {
      if (this.formSecond.mail === "") {
        return;
      }
      const formData = { mail: this.formSecond.mail };
      this.$emit("submitForm", { formData: formData, step: 1 });
    },
  },
};
</script>

<style lang="scss">
.step-form {
  padding: 20px 10px 20px;

  @media (max-width: 768px) {
    padding: 10px 0 15px;
  }

  &__title {
    grid-column: 1 / -1;

    margin-bottom: 25px;

    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 26px;
      text-align: left;
    }
  }

  &__subtitle {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__text {
    font-size: 15px;
    line-height: 18px;
    color: #3e3e3e;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 15px;
    }
  }

  &__input {
    padding: 8px 10px;
    max-width: 350px;

    font-size: 15px;
    line-height: 17px;
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 5px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__button {
    padding: 12px 10px;
    max-width: 350px;

    font-size: 15px;
    line-height: 17px;
    font-weight: 700;
    background-color: #3bbfdd;
    color: #fff;
    border: none;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__result {
    padding: 20px 15px 10px;
    margin: 0 0 15px;

    background-color: #f7f6f6;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 8px 8px;
    }

    &-title {
      margin-bottom: 20px;

      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }

    &-wrap {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(3, 1fr);
      gap: 5px 10px;
      grid-auto-flow: column;

      @media (max-width: 992px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-auto-flow: row;
      }
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      margin-bottom: 0px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        & b {
          display: inline-block;

          min-width: 115px;
        }
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        & b {
          display: inline-block;

          min-width: 95px;
        }
      }

      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        & b {
          display: inline-block;

          min-width: 120px;
        }
      }

      @media (max-width: 1250px) {
        & b {
          min-width: auto !important;
        }
      }

      & span {
        margin-right: 1px;

        font-size: 18px;
        line-height: 18px;
        font-weight: 700;

        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 16px;
        }

        // background-color: #90949f;
      }

      & p {
        flex-grow: 1;
        display: block;
        padding: 7px;

        font-size: 18px;
        line-height: 18px;
        font-weight: 300;

        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 16px;
        }
      }
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;

    padding: 20px 15px;

    background-color: #f7f6f6;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;

      padding: 15px 8px;
    }

    &-img {
      grid-column: 2 / 3;
      grid-row: 2 / 9;
      align-self: stretch;

      object-fit: contain;

      @media (max-width: 768px) {
        grid-column: auto;
        grid-row: auto;

        margin-bottom: 10px;
      }
    }

    &-subtitle {
      margin-bottom: 15px;
    }

    &-text {
      margin-bottom: 8px;
    }

    &-input {
      margin-top: 15px;

      &::placeholder {
        opacity: 0.8;
      }

      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }

    &-button {
      margin-top: 15px;

      @media (max-width: 768px) {
        width: 100%;

        padding: 12px 10px;
        margin-top: 12px;

        text-align: center;
      }
    }
  }

  &__subscribe {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 0 10px;
    justify-content: flex-start;
    align-items: center;

    margin-top: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 3fr 2fr;

      padding: 10px 8px;
    }

    &-checkbox {
      appearance: none;
    }

    &-checkmark {
      position: relative;
      grid-column: 1 / 2;
      grid-row: 1 / -1;
      align-self: flex-start;

      margin-top: 5px;
      width: 18px;
      height: 18px;

      & span {
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border: 2px solid #000;
        border-radius: 50%;
      }

      & input:checked ~ span::after {
        position: absolute;
        top: 2px;
        left: 4px;

        transform: translate(-50%, -50%);
        width: 6px;
        height: 9px;
        border: solid var(--primary);
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        transform-origin: center;
        content: "";
      }
    }

    &-input {
      &--error {
        border: 1px solid red;
      }
    }

    &-button {
      max-width: 200px;
    }

    &-subtitle {
      grid-column: 1 / -1;
      margin-right: 10px;
      margin-bottom: 16px;

      @media (max-width: 768px) {
        // grid-row: 1 / 2;

        margin-right: 0;
      }
    }

    &-text {
      grid-column: 1 / -1;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        grid-column: 1 / -1;
        // grid-row: 2 / 3;
      }
    }
  }
}
</style>
