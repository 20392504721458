<template>
  <div class="step-planning">
    <h4 class="step-planning__title">Vielen Dank!</h4>

    <p class="step-planning__text">
      Wir haben deine Terminanfrage erhalten und werden uns innerhalb der
      nächsten 48h melden, um einen Gesprächstermin zu vereinbaren.
    </p>

    <div class="step-planning__sep"></div>

    <div class="step-planning__box">
      <h5 class="step-planning__box-title">Deine Vorteile bei Merx</h5>

      <MainList :list="list" />

      <Rating />
    </div>
  </div>
</template>

<script>
import MainList from "@/components/Base/MainList.vue";
import Rating from "@/components/Rating.vue";

export default {
  name: "Planning",
  components: {
    MainList,
    Rating,
  },
  data() {
    return {
      list: [
        "Passgenau auf deinen Grundriss abgestimmt",
        "Mit Elektrogeräten von Top-Herstellern",
        "Professionelle Beratung von Küchenexperten",
      ],
    };
  },
  mounted() {
    this.$gtm.trackView('Preisrechner Bestätigung Terminanfrage', 'https://preisrechner.merx.ch/confirmation-contact-request');
  },
  methods: {
    selectItem(value) {
      this.selected = value;
      this.$emit("changeValue", value);
    },
    nextStep() {
      if (this.selected) {
        this.$emit("nextStep");
      }
    },
  },
};
</script>

<style lang="scss">
.step-planning {
  padding: 35px 10px 20px;

  &__title {
    margin-bottom: 30px;

    font-family: Helvetica;
    font-size: 31px;
    line-height: 37px;
    font-weight: 700;
    text-align: center;

    @media (max-width: 768px) {
      color: var(--primary);
    }
  }

  &__text {
    max-width: calc(100% - 50px);
    margin: 0 auto 40px;

    font-family: Helvetica;
    font-size: 16px;
    line-height: 18px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__sep {
    width: 200px;
    height: 2px;
    margin: 0 auto;

    background-color: #000;
    text-align: center;
  }

  &__box {
    padding: 25px 15px;
    margin-top: 50px;

    border: 1px solid #ccc;
    border-radius: 5px;

    max-width: 500px;
    margin-right: auto;
    margin-left: auto;

    &-title {
      margin-bottom: 30px;

      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      text-align: center;

      @media (max-width: 768px) {
        margin-bottom: 20px;

        font-size: 15px;
        line-height: 19px;
      }
    }

    & .main-list {
      margin-bottom: 20px;
    }
  }

  & .rating {
    justify-content: center;
  }
}
</style>