<template>
  <ul class="main-list">
    <li v-for="item in list" :key="item" class="main-list__item">
      <div class="main-list__circle" />
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "MainList",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.main-list {
  // @media (max-width: 768px) {
  //   margin-bottom: 20px;
  // }

  &__circle {
    position: relative;
    flex-shrink: 0;

    width: 26px;
    height: 26px;
    margin-right: 15px;

    // background-color: red;
    border-radius: 50%;
    border: 3px solid var(--primary);

    @media (max-width: 768px) {
      margin-right: 8px;

      border: 2px solid var(--primary);
    }

    &::after {
      position: absolute;
      top: 10px;
      left: 4.5px;
      width: 7px;
      height: 12px;
      transform: rotate(48deg) translate(-50%, -50%);
      transform-origin: center;
      border: solid var(--primary);
      border-width: 0 3px 3px 0;
      content: "";

      @media (max-width: 768px) {
        border-width: 0 2px 2px 0;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;

    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    // color: #000;

    &:not(:last-child) {
      margin-bottom: 18px;

      @media (max-width: 768px) {
        margin-bottom: 12px;
      }
    }

    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>