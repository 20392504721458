<template>
  <div id="main_button" class="button" @click="showSteps">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    text: {
      type: String,
      default: "Button",
    },
  },
  methods: {
    showSteps() {
      this.$root.$data.isShowSteps = true;
    },
  },
};
</script>

<style lang="scss">
.button {
  display: inline-block;

  padding: 16px 30px;
  margin: 0 auto;

  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #fff;
  background-color: var(--primary);
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>