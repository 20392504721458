<template>
  <header class="header">
    <div class="header__container">
      <div class="header__logo">
        <img src="@/assets/img/logo.svg" alt="" />
      </div>

      <div class="header__info">
        <a href="tel:+78142332211" class="header__info-tel">0800 313 313</a>
        <div class="header__info-weekday">Mo-Fr&nbsp;&nbsp;8 bis 18 Uhr</div>
        <div class="header__info-weekend">Sa&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9 bis 16 Uhr</div>
        <i class="fas fa-phone-alt"></i>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss">
.header {
  border-bottom: 1px solid var(--primary);

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    max-width: 1500px;
    padding: 10px 20px;
    margin: 0 auto;
  }

  &__logo {
    width: 240px;

    @media (max-width: 768px) {
      width: 120px;
    }
  }

  &__info {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    align-self: flex-end;

    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 8px;

    &-tel {
      margin-bottom: 5px;

      font-size: 26px;
      line-height: 32px;
      font-weight: 700;

      @media (max-width: 900px) {
        font-size: 15px;
        line-height: 18px;
      }
    }

    &-weekday,
    &-weekend {
      font-size: 14px;
      line-height: 18px;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 15px;
      }
    }

    & i {
      grid-column: 1 / 2;
      grid-row: 1 / 4;

      margin-top: 8px;

      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
  }
}
</style>