<template>
  <div class="partners">
    <div class="container">
      <img
        v-for="img in listImgs"
        :key="img.name"
        :src="require(`@/assets/img/brand/${img.srcImg}`)"
        alt="Samsung"
        class="partners__item"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Partners",
  data() {
    return {
      listImgs: [
        {
          name: "Electrolux",
          srcImg: "electrolux.png",
        },
        {
          name: "Miele",
          srcImg: "miele.png",
        },
        {
          name: "Samsung",
          srcImg: "samsung.png",
        },
        {
          name: "Bosch",
          srcImg: "bosch.png",
        },
        {
          name: "AEG",
          srcImg: "aeg.png",
        },
        {
          name: "Siemens",
          srcImg: "siemens.png",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.partners {
  padding: 20px 0;

  background-color: #f2f2f2;

  & > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 800px) {
      display: grid;
      grid-template-columns: repeat(3, auto);
      justify-content: space-between;
    }
  }

  &__item {
    padding: 10px;
  }
}
</style>