<template>
  <div class="step-item">
    <h4 class="step-item__title">Welche Form soll deine Küche haben?</h4>

    <div class="step-item__list">
      <div
        v-for="item in items"
        :key="item.value"
        class="step-item__item"
        :class="{ selected: item.value === selected }"
        @click="selectItem(item.value)"
      >
        <img
          :src="require(`@/assets/img/step1/${item.img}.svg`)"
          alt=""
          class="step-item__item-img"
        />

        <p class="step-item__item-name">{{ item.name }}</p>
      </div>
    </div>

    <div class="step-item__btns">
      <!-- <button @click="$emit('prevStep')" class="step-item__btns-item step-item__btns-item--prev">
        Zurück
      </button> -->
      <button
        @click="nextStep"
        id="next-step-1"
        class="step-item__btns-item step-item__btns-item--next"
      >
        Weiter →
      </button>
      <p v-if="error" class="step-item__btns-error">Bitte Küchenform wählen!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step1",
  data() {
    return {
      selected: null,
      error: false,
      items: [
        {
          name: "L-Form",
          value: "L-Form",
          img: "item-1",
        },
        {
          name: "U-Form",
          value: "U-Form",
          img: "item-2",
        },
        {
          name: "Insel",
          value: "Insel",
          img: "item-3",
        },
        {
          name: "Küchenzeile",
          value: "Küchenzeile",
          img: "item-4",
        },
      ],
    };
  },
  mounted() {
    this.$gtm.trackView('Preisrechner Step 1', 'https://preisrechner.merx.ch/step-1');
  },
  methods: {
    selectItem(value) {
      this.selected = value;
    },
    nextStep() {
      if (this.selected) {
        this.$emit("nextStep", this.selected, "Form");
      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss">
.step-item {
  padding: 35px 10px 28px;

  background-color: #f5f5f5;

  &__title {
    margin-bottom: 40px;

    font-family: Helvetica;
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    text-align: center;

    @media (max-width: 768px) {
      margin-bottom: 15px;

      font-size: 16px;
      line-height: 19px;
    }
  }

  &__list {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // gap: 30px;

    // display: flex;

    display: flex;
    justify-content: center;
    gap: 30px;

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    & > * {
      flex: 0 1 25%;
    }

    // & > * {
    //   flex-grow: 1;
    // }

    // & > *:not(:last-child) {
    //   margin-right: 30px;

    //   @media (max-width: 768px) {
    //     margin-right: 0;
    //   }
    // }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 20px;

    background-color: #fff;
    border: 4px solid transparent;

    @media (max-width: 768px) {
      padding: 12px;
    }

    &.selected {
      border-color: var(--primary);
    }

    &-img {
      width: 100%;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }

    &-name {
      font-family: Helvetica;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 15px;
      }
    }
  }

  &__btns {
    display: flex;
    position: relative;

    @media (max-width: 768px) {
      flex-wrap: wrap;

      margin-top: 20px;
    }

    &-item {
      padding: 15px 120px;
      margin-top: 40px;

      font-size: 18px;
      line-height: 21px;
      border-radius: 0;
      border: 1px solid transparent;
      background-color: #fdfdfd;
      text-align: center;
      cursor: pointer;

      @media (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 10px 20px;
        margin: 5px 20px;
      }

      &--prev {
        border-color: #000;
      }

      &--next {
        margin-left: auto;

        border-color: #000;

        @media (max-width: 768px) {
          order: -1;
        }
      }
    }

    &-error {
      color: red;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>