<template>
  <div>
    <Header />

    <First />

    <Partners />

    <Get />

    <Kitchens />

    <Plane v-if="isShowPlane" />

    <Reviews />

    <Experts />

    <Plane />

    <Footer />

    <keep-alive>
      <Steps v-if="isShowSteps" />
    </keep-alive>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import First from "@/components/Sections/First.vue";
import Get from "@/components/Sections/Get.vue";
import Kitchens from "@/components/Sections/Kitchens.vue";
import Plane from "@/components/Sections/Plane.vue";
import Reviews from "@/components/Sections/Reviews.vue";
import Footer from "@/components/Footer.vue";
import Experts from "@/components/Sections/Experts.vue";
import Steps from "@/components/Steps/Wrap.vue";
import Partners from "@/components/Sections/Partners.vue";

export default {
  name: "App",
  components: {
    Header,
    First,
    Get,
    Kitchens,
    Footer,
    Plane,
    Reviews,
    Experts,
    Steps,
    Partners,
  },
  data() {
    return {
      isShowSteps: false,

      values: {
        step1: null,
        step2: null,
        step3: null,
        step4: null,
        step5: null,
        step6: null,
        step7: null,
        step8: null,
      },
    };
  },
mounted() {
    this.$gtm.trackView('Preisrechner Home', 'https://preisrechner.merx.ch/');
  },
  computed: {
    isShowPlane() {
      if (document.documentElement.clientWidth > 768) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
:root {
  // --primary: #00b2bf;
  --primary: #03c9d6;
  --primary-darken: #00b2bf;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: "Roboto", sans-serif;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

ul {
  list-style: none;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1200px;

  padding: 0 20px;
  margin: 0 auto;

  @media (max-width: 900px) {
    padding: 0 10px;
  }
}
</style>
