<template>
  <div class="first">
    <div class="first__container">
      <div class="first__left">
        <h1 class="first__left-title">Plane deine individuelle Traumküche</h1>

        <MainList :list="list" />

        <MainButton
          text="Küchenplanung starten"
          class="first__left-button"
        />

        <Rating class="first__left-rating" />

        <p class="first__left-text">dauert nur wenige Minuten!</p>
      </div>

      <div class="first__right">
        <swiper
          :slides-per-view="1"
          :space-between="15"
          navigation
          :pagination="{ clickable: true }"
        >
          <swiper-slide
            class="first__item-img"
            v-for="item in sliderList"
            :key="item.title"
          >
            <img
              :src="require(`@/assets/${item.img}`)"
              alt=""
              class="first__item-img"
            />

            <a :href="item.url" target="_blank" class="first__item-price">ab CHF {{ item.price }},-</a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/Base/MainButton.vue";
import MainList from "@/components/Base/MainList.vue";
import Rating from "@/components/Rating.vue";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Navigation, Pagination]);

export default {
  name: "First",
  components: {
    MainButton,
    MainList,
    Rating,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      list: [
        "Passgenau auf deinen Grundriss abgestimmt",
        "Mit Elektrogeräten von Top-Herstellern",
        "Professionelle Fachberatung von Küchenexperten",
      ],

      sliderList: [
        {
          title: "Die Trendige",
          price: 3950,
          img: "img-1.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/die-dixa-als-einzeilige-kueche.html",
        },
        {
          title: "Die Elegante",
          price: 5520,
          img: "img-2.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/die-elegante.html",
        },
        {
          title: "Die Harmonische",
          price: 4450,
          img: "img-3.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/54.html",
        },
        {
          title: "Die Verspielte",
          price: 4450,
          img: "img-4.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/53.html",
        },
        {
          title: "Die Dunkle",
          price: 5520,
          img: "img-5.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/51.html",
        },
        {
          title: "Die Futuristische",
          price: 8150,
          img: "img-6.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/die-luxa-als-insel-kueche.html",
        },
      ],
    };
  },
};
</script>

<style lang='scss'>
.first {
  padding: 60px 0 40px;

  @media (max-width: 800px) {
    padding: 20px 0;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 600px;
    gap: 60px;
    align-items: center;

    max-width: 1500px;

    padding: 0 20px;
    margin: 0 auto;

    @media (max-width: 992px) {
      grid-template-columns: 100%;
      gap: 20px;

      padding: 0 12px;
    }
  }

  &__left {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 20px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }

    &-title {
      grid-column: 1 / -1;

      margin-bottom: 45px;

      font-size: 30px;
      line-height: 42px;
      font-weight: 700;

      @media (max-width: 992px) {
        margin-bottom: 25px;

        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }
    }

    &-text {
      margin-top: 4px;

      font-size: 12px;
      line-height: 16px;
      text-align: center;

      @media (max-width: 1200px) {
        order: 11;
      }
    }

    &-button {
      align-self: flex-start;

      @media (max-width: 1200px) {
        order: 10;
      }
    }

    & .rating {
      margin-bottom: 15px;
    }
  }

  & .main-list {
    grid-column: 1 / -1;

    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__right {
    @media (max-width: 768px) {
      margin: 0 -12px;
    }

    & .swiper-container {
      padding-bottom: 18px;
    }

    & .swiper-button-prev,
    & .swiper-button-next {
      &::after {
        font-size: 30px;
        font-weight: 900;
        color: #000;
      }
    }

    & .swiper-pagination {
      bottom: 0px;

      &-bullet {
        width: 10px;
        height: 10px;
        margin: 0 2px !important;

        background-color: transparent;
        border: 1px solid #000;
        opacity: 1;

        &-active {
          background-color: #000;
        }
      }
    }
  }

  &__item {
    position: relative;

    &-price {
      position: absolute;
      top: 20px;
      left: 0;

      padding: 12px 20px;

      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      color: #fff;
      background-color: var(--primary);
    }
  }
}
</style>