<template>
  <div class="rating">
    <img src="@/assets/img/rating.png" alt="" class="rating__img" />

    <i v-for="item in value" :key="item" class="fas fa-star"></i>

    <div class="rating__number">
      5 / <span class="rating__number--grey">5</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rating",
  props: {
    value: {
      type: Number,
      default: 5,
    },
  },
};
</script>

<style lang='scss'>
.rating {
  display: flex;
  align-items: center;

  margin: 0 auto;

  &__img {
    height: 50px;
    margin-right: 8px;
  }

  & i {
    margin: 0 1px;

    font-size: 18px;
    color: #fee900;
  }

  &__number {
    margin-top: 5px;
    margin-left: 8px;

    font-size: 12px;
    line-height: 14px;
    font-weight: 700;

    &--grey {
      color: #717171;
    }
  }
}
</style>