<template>
  <div class="kitchen">
    <div class="container">
      <h2 class="kitchen__title">
          Unsere fertigen Küchen zur Inspiration
      </h2>

      <div class="kitchen__list">
        <div class="kitchen__item" v-for="(item, index) in list" :key="index">
          <div class="kitchen__item-info">
            <div class="kitchen__item-title">
              {{ item.title }}
            </div>

            <div class="kitchen__item-price">ab CHF {{ item.price }},-</div>

            <a :id="'kitchen-' + index" class="kitchen__item-link" :href="item.url" target="_blank">Küche ansehen</a>
          </div>
          <img :src="require(`@/assets/${item.img}`)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Kitchen",
  data() {
    return {
      list: [
        {
          title: "Die Trendige",
          price: 3950,
          img: "img-1.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/die-dixa-als-einzeilige-kueche.html",
        },
        {
          title: "Die Elegante",
          price: 5520,
          img: "img-2.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/die-elegante.html",
        },
        {
          title: "Die Harmonische",
          price: 4450,
          img: "img-3.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/54.html",
        },
        {
          title: "Die Verspielte",
          price: 4450,
          img: "img-4.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/53.html",
        },
        {
          title: "Die Dunkle",
          price: 5520,
          img: "img-5.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/51.html",
        },
        {
          title: "Die Futuristische",
          price: 8150,
          img: "img-6.jpg",
          url: "https://www.merx.ch/kuechen/inspiration/inspiration-detail/die-luxa-als-insel-kueche.html",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.kitchen {
  padding: 40px 0 10px;

  @media (max-width: 768px) {
    padding: 20px 0;
  }

  &__title {
    margin-bottom: 40px;

    font-size: 28px;
    line-height: 35px;
    font-weight: 700;
    text-align: center;
    color: var(--primary);

    @media (max-width: 768px) {
      margin-bottom: 20px;

      font-size: 24px;
      line-height: 29px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 50px;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }

  &__item {
    position: relative;

    &-info {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 30px;

      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 0px 80px;
      justify-content: space-between;

      padding: 4px 20px 6px;

      color: #fff;
      background-color: var(--primary);

      @media (max-width: 900px) {
        bottom: 0;

        gap: 5px 30px;

        padding: 8px 20px;
      }
    }

    &-title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;

      @media (max-width: 900px) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &-price {
      font-size: 14px;
      line-height: 18px;

      @media (max-width: 900px) {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &-link {
      grid-row: 1 / 3;
      grid-column: 2 / 3;
      align-self: center;

      padding: 8px 20px;

      font-family: Helvetica;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      border: 1px solid #fff;
      border-radius: 5px;

      @media (max-width: 768px) {
        padding: 6px 20px;

        font-size: 14px;
        line-height: 17px;
      }

      @media (max-width: 350px) {
        padding: 6px 8px;
      }
    }
  }
}
</style>