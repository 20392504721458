<template>
  <div class="plane">
    <div class="container">
      <div class="plane__left">
        <div class="plane__left-title">Plane deine Traumküche</div>

        <MainButton text="Küchenplanung starten" />

        <p class="plane__left-text">dauert nur wenige Minuten!</p>
      </div>

      <div class="plane__right">
        <MainList :list="list" />
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/Base/MainButton.vue";
import MainList from "@/components/Base/MainList.vue";

export default {
  name: "Plane",
  components: { MainButton, MainList },
  data() {
    return {
      list: [
        "Passgenau auf deinen Grundriss abgestimmt",
        "Mit Elektrogeräten von Top-Herstellern",
        "Professionelle Fachberatung von Küchenexperten",
      ],
    };
  },
};
</script>

<style lang="scss">
.plane {
  padding: 32px 0;
  margin: 60px 0 80px;

  background-color: #f7f6f6;

  @media (max-width: 768px) {
    padding: 20px 0 0;
    margin: 20px 0 0;
  }

  & > .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 10px;

    text-align: center;

    @media (max-width: 768px) {
      padding: 0;
    }

    &-title {
      margin-bottom: 20px;

      font-size: 30px;
      line-height: 42px;
      font-weight: 700;

      @media (max-width: 992px) {
        margin-bottom: 5px;

        font-size: 20px;
        line-height: 28px;
      }
    }

    &-text {
      margin-top: 8px;

      font-size: 12px;
      line-height: 16px;
    }
  }

  &__right {
    padding: 15px 25px 15px 45px;

    background-color: #fff;

    display: flex;
    align-items: center;

    @media (max-width: 1100px) {
      padding: 20px 10px;
    }
  }
}
</style>