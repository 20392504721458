<template>
  <div class="get">
    <div class="container">
      <h2 class="get__title">So einfach geht's</h2>

      <div class="get__list">
        <div class="get__item" v-for="(item, index) in list" :key="item.title">
          <img
            :src="require(`@/assets/img/${item.src}`)"
            alt=""
            class="get__item-img"
          />
          <h4 class="get__item-title">
            <span class="get__item-title--index">{{ index + 1 }}.</span>
            {{ item.title }}
          </h4>
          <p class="get__item-description">
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Get",
  data() {
    return {
      list: [
        {
          title: "Küche online konfigurieren",
          description: "Je mehr wir über deine Wünsche wissen, desto besser können wir deine Küche planen.",
          src: "get-1.jpg",
        },
        {
          title: "Details klären",
          description: "Wir kontaktieren dich, um einen Termin für ein Gespräch zu vereinbaren, um offene Details zu klären.",
          src: "get-2.jpg",
        },
        {
          title: "Feinplanung mit Experten",
          description: "Im Video-Chat oder Store planen wir in 3D deine neue Traumküche. Alle Wünsche und Änderungen werden berücksichtigt.",
          src: "get-3.jpg",
        },
        {
          title: "Lieferung & Montage",
          description: "Deine Küche wird innerhalb kürzester Zeit geliefert und montiert. Die alte Küche können wir demontieren und entsorgen (optional).",
          src: "get-4.jpg",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.get {
  padding: 60px 0 40px;

  text-align: center;

  @media (max-width: 768px) {
    padding: 30px 0;

    text-align: left;
  }

  &__title {
    margin-bottom: 80px;

    font-size: 32px;
    line-height: 44px;
    color: var(--primary);
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 768px) {
      margin-bottom: 12px;

      font-size: 24px;
      line-height: 33px;
      text-transform: none;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }

  &__item {
    &-img {
      width: 100%;
      height: 200px;

      margin-bottom: 20px;

      object-fit: cover;

      @media (max-width: 992px) {
        height: auto;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    &-title {
      margin-bottom: 10px;

      font-size: 18px;
      font-family: Helvetica;
      line-height: 22px;

      @media (max-width: 768px) {
        margin-bottom: 6px;

        font-size: 16px;
        line-height: 19px;
      }

      &--index {
        display: none;

        font-size: 26px;
        line-height: 36px;
        color: var(--primary);

        @media (max-width: 768px) {
          display: inline;
        }
      }
    }

    &-description {
      color: #323232;

      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      hyphens: auto;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
</style>