<template>
  <div class="reviews">
    <div class="container">
      <div class="reviews__first">
        <h2 class="reviews__title">Das Sagen Unsere Kunden</h2>

        <Rating />
      </div>

      <div class="reviews__list">
        <div v-for="item in reviews" :key="item.text" class="reviews__item">
          <img
            src="@/assets/img/review-icon.png"
            alt=""
            class="reviews__item-mark"
          />
          <p class="reviews__item-text">"{{ item.text }}"</p>

          <div class="reviews__item-stars">
            <i v-for="item in 5" :key="item" class="fas fa-star"></i>
          </div>

          <p class="reviews__item-data">
            {{ item.data }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from "@/components/Rating.vue";

export default {
  name: "Reviews",
  components: { Rating },
  data() {
    return {
      reviews: [
        {
          text: "Die Qualität der Küche hat uns komplett überzeugt und wir sind happy, dass wir sie in unserem Budget realisieren konnten.",
          data: "Sabine",
        },
        {
          text: "Bei MERX erhält man wirklich viel Küche für sein Geld!",
          data: "Corinne & Edi",
        },
        {
          text: "Das ganze Projekt hat super geklappt und wir sind happy mit unserer neuen Küche.",
          data: "Michèle & Andreas",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.reviews {
  padding: 20px 0 40px;

  @media (max-width: 768px) {
    padding: 20px 0;
  }

  &__first {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 10px 60px;
    align-items: center;

    margin-bottom: 60px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;

      margin-bottom: 25px;
    }

    & .rating {
      grid-column: 3 / 4;

      @media (max-width: 768px) {
        grid-column: auto;
      }
    }
  }

  &__title {
    grid-column: 2 / 3;

    font-size: 24px;
    line-height: 29px;
    color: var(--primary-darken);
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 768px) {
      grid-column: auto;

      font-size: 24px;
      line-height: 29px;
    }
  }

  &__list {
    display: flex;
    // grid-template-columns: repeat(3, 1fr);
    // gap: 100px;
    justify-content: space-between;

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 250px;
      gap: 15px;
      justify-content: center;
    }

    & > * {
      flex: 0 0 270px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 40px 40px 25px;

    color: #fff;
    background-color: var(--primary-darken);
    text-align: center;

    @media (max-width: 768px) {
      padding: 30px 25px 20px;
      margin: 0 auto;
      max-width: 300px;
    }

    &-mark {
      margin-bottom: 25px;
    }

    &-text {
      margin-bottom: auto;

      font-size: 18px;
      line-height: 28px;
      font-style: italic;
      font-weight: 300;
    }

    &-data {
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
    }

    &-stars {
      margin-top: 50px;

      & i {
        font-size: 20px;
        margin: 0 1px;
      }
    }

    &-data {
      margin-top: 20px;
    }
  }
}
</style>