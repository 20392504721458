import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import App from './App.vue';
import store from './store';

const app = createApp(App);
app.use(store);

app.use(
    createGtm({
      id: 'GTM-TK45DPT',
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      nonce: '2726c7f26c', // Will add `nonce` to the script tag
      debug: true,
      trackOnNextTick: true // Whether or not call trackView in Vue.nextTick
    })
  );

app.mount('#app');
