<template>
  <div class="footer-top">
    <div class="container">
      <div class="footer-top__item">
        <img src="@/assets/img/user-icon.png" alt="" />
        Kundenservice: 0800 313 313
      </div>
      <div class="footer-top__item">
        <img src="@/assets/img/heart-icon.png" alt="" />
        Beratung kostenlos und unverbindich
      </div>
      <div class="footer-top__item">
        <img src="@/assets/img/star-icon.png" alt="" />
        Top Marken-Auswahi
      </div>
    </div>
  </div>

  <footer class="footer">
    <div class="container">
      <div class="footer__one">
        <h5 class="footer__subtitle">Merx AG</h5>

        <p class="footer__text">Küchen und Geräte</p>

        <ul class="footer__one-list">
          <li>
            <a href="https://www.merx.ch/shop/agb?_ga=1.48618468.319278944.1476433916" target="_blank" class="footer__link">AGB</a>
          </li>
          <li>
            <a href="https://www.merx.ch/shop/datenschutz" target="_blank" class="footer__link">Datenschutz</a>
          </li>
          <li>
            <a href="https://www.merx.ch/kuechen/merx/medieninformation.html" target="_blank" class="footer__link">Medieninformationen</a>
          </li>
          <li>
            <a href="https://www.merx.ch/kuechen/merx/impressum.html" target="_blank" class="footer__link">Impressum</a>
          </li>
        </ul>
      </div>

      <div class="footer__social">
      </div>

      <div class="footer__info">
        <h5 class="footer__subtitle">Service</h5>

        <p class="footer__text">Hast Du noch Fragen?</p>
        <a href="#" class="footer__tel">0800 313 313</a>
        <a href="mailto:welcome@merx.ch" class="footer__link">welcome@merx.ch</a>
      </div>

      <div class="footer__copy">&copy;2021 merx.ch, alle Rechte vorbehalten</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.footer {
  padding: 25px 0;
  background-color: #f2f2f2;

  @media (max-width: 768px) {
    border-top: 2px solid var(--primary-darken);
  }

  &-top {
    padding: 15px 0;
    color: #fff;
    background-color: var(--primary-darken);

    @media (max-width: 1100px) {
      display: none;
    }

    &__item {
      display: flex;
      align-items: center;

      font-size: 14px;
      line-height: 17px;
      font-weight: 300;
    }

    & > .container {
      display: grid;
      grid-template-columns: repeat(3, auto);
      justify-content: space-between;
    }

    & img {
      flex-shrink: 0;

      margin-right: 15px;
    }
  }

  & > .container {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 30px;
    justify-content: space-between;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;

      padding: 0 40px;
    }
  }

  &__subtitle {
    margin-bottom: 8px;

    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    text-transform: uppercase;
    color: #474747;
  }

  &__text,
  &__link {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    color: #323232;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__tel {
    display: block;

    margin-top: 8px;

    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    color: #323232;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  & img {
    max-width: 20px;
  }

  &__one {
    &-list {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 5px 30px;

      margin-top: 20px;

      @media (max-width: 568px) {
        grid-template-columns: 1fr;
        gap: 3px;
      }
    }
  }

  &__social {
    &-list {
      display: flex;

      margin-top: 20px;

      @media (max-width: 768px) {
        margin-top: 15px;
      }

      & i {
        font-size: 25px;
        margin-right: 18px;
      }
    }
  }

  &__one,
  &__info,
  &__social {
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__copy {
    grid-column: 1 / -1;
    padding-top: 15px;

    border-top: 1px solid var(--primary-darken);

    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    color: #323232;
  }
}
</style>