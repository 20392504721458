<template>
  <div class="step-item">
    <h4 class="step-item__title">
      Auf welcher Höhe soll der Backofen angebracht sein?
    </h4>

    <div class="step-item__list">
      <div
        v-for="item in items"
        :key="item.value"
        class="step-item__item"
        :class="{ selected: item.value === selected }"
        @click="selectItem(item.value)"
      >
        <img
          :src="require(`@/assets/img/step6/${item.img}.svg`)"
          alt=""
          class="step-item__item-img"
        />

        <p class="step-item__item-name">{{ item.name }}</p>
      </div>
    </div>

    <div class="step-item__btns">
      <button
        @click="$emit('prevStep')"
        class="step-item__btns-item step-item__btns-item--prev"
      >
        Zurück
      </button>
      <button
        @click="nextStep"
        id="next-step-6"
        class="step-item__btns-item step-item__btns-item--next"
      >
        Weiter →
      </button>
      <p v-if="error" class="step-item__btns-error">Bitte Position wählen!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step6",
  data() {
    return {
      selected: null,
      error: false,
      items: [
        {
          name: "Unter dem Kochfeld",
          value: "Unter dem Kochfeld",
          img: "item-1",
        },
        {
          name: "Auf Augenhöhe",
          value: "Auf Augenhöhe",
          img: "item-2",
        },
      ],
    };
  },
  mounted() {
    this.$gtm.trackView('Preisrechner Step 6', 'https://preisrechner.merx.ch/step-6');
  },
  methods: {
    selectItem(value) {
      this.selected = value;
    },
    nextStep() {
      if (this.selected) {
       this.$emit("nextStep", this.selected, 'Backofen');
      } else {
        this.error = true
        setTimeout(() => {
          this.error = false
        }, 3000);
      }
    },
  },
};
</script>